import React from 'react';

const Intro = () => {
    return (
        <>
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                        <img
                            alt="Introduction image"
                            className="rounded-t float-right"
                            src="https://cdn.juicecloud.cfd/u/home.jpg"
                        />
                    </div>
                    <div
                        className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
                        data-aos="zoom-in"
                        data-aos-delay="500"
                    >
                        <h3 className="text-3xl text-blue-900 font-bold">
                            We specialize in delivering high-quality construction projects on time and within budget.
                        </h3>
                        <div>
                            <p className="my-3 text-xl text-gray-600 font-semibold">
                                At Prutha Builders, we don’t just construct buildings - We create spaces where dreams are realized and trust is cemented.
                            </p>
                        </div>

                        <div>
                            <p className="my-3 text-xl text-gray-600 font-semibold">
                                We seamlessly blend timeless traditions with modern technology to deliver homes, offices, and spaces that embody durability
                                and elegance. Whether you're envisioning a dream home or a landmark commercial space, Prutha Builders is your partner in
                                creating enduring legacies where innovation meets tradition for a brighter tomorrow.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Intro;
