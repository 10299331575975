import React from 'react';

const Services = () => {
    return (
        <div id="services" className="bg-gray-100 py-12">
            {/* Portfolio Section */}
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Portfolio</h2>
                    <div className="flex justify-center">
                        <div className="w-24 border-b-4 border-blue-900"></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        Take a look at a few of our projects
                    </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        {/* Card 1 */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img
                                    alt="card img"
                                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                    src="https://cdn.juicecloud.cfd/u/21.jpg"
                                />
                                <h2 className="font-semibold my-4 text-2xl text-center">Apartments</h2>
                                <p className="text-md font-medium">
                                    We specialize in constructing apartment complexes that combine style, practicality, and community living. From
                                    multi-family residences to high-rise apartments, we focus on efficient designs, sustainable materials, and modern
                                    amenities.
                                </p>
                            </div>
                        </div>

                        {/* Card 2 */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img
                                    alt="card img"
                                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                    src="https://cdn.juicecloud.cfd/u/22.jpg"
                                />
                                <h2 className="font-semibold my-4 text-2xl text-center">Residential Housing</h2>
                                <p className="text-md font-medium">
                                    We build custom homes and residential communities tailored to individual lifestyles and preferences. Our residential
                                    projects focus on high-quality construction, thoughtful design, and attention to detail, from single-family homes to
                                    large residential developments.
                                </p>
                            </div>
                        </div>

                        {/* Card 3 */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img
                                    alt="card img"
                                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                    src="https://cdn.juicecloud.cfd/u/23.jpg"
                                />
                                <h2 className="font-semibold my-4 text-2xl text-center">Commercial Buildings</h2>
                                <p className="text-md font-medium">
                                    We deliver functional, aesthetically appealing structures that meet the demands of the modern marketplace. Whether
                                    you're looking to build a corporate headquarters, shopping center, or mixed-use development, we emphasize efficient
                                    layouts, innovative design, and future-proof solutions that enhance productivity and customer experience.
                                </p>
                            </div>
                        </div>

                        {/* Card 4 */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img
                                    alt="card img"
                                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                    src="https://cdn.juicecloud.cfd/u/24.jpg"
                                />
                                <h2 className="font-semibold my-4 text-2xl text-center">Dynamic Homes</h2>
                                <p className="text-md font-medium">
                                    With a commitment to using premium materials and sustainable practices, we create homes that are not only beautiful
                                    but also energy-efficient, durable, and comfortable for years to come.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* New Section Added */}
            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'>
                                    <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                                    <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                                </svg>
                            </div>
                            <h3 className="text-3xl text-blue-900 font-bold">Turning Blueprints Into <span className='font-black'>Reality</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    Whether it’s residential, commercial, or industrial projects, we pride ourselves on delivering quality craftsmanship, using cutting-edge technologies, and adhering to the highest industry standards. We don't just build structures – we build trust, reliability, and long-lasting value for our clients.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'>
                                    <path d="M12 2a2 2 0 0 1 2 2v7h7a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-7v7a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-7H4a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h7V4a2 2 0 0 1 2-2h2z"></path>
                                </svg>
                            </div>
                            <h3 className="text-3xl text-blue-900 font-bold">Our<span className='font-black'>Vision</span></h3>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>
                            Welcome to Prutha Constructions, where your vision meets expert craftsmanship. Founded by Shilpa Prakash B, we specialize in transforming ideas into solid, lasting structures with precision and dedication. From residential to commercial projects, we ensure quality and trust in every build.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Services;
